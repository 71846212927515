<template>
  <site-monthly-report :site-id="siteId" :report-id="reportId" />
</template>

<script>
export default {
  name: "SiteReport",
  components: {
    "site-monthly-report": () =>
      import("@shared/sites/report/_siteMonthlyReport")
  },
  data() {
    return {
      siteId: this.$route.params.siteId,
      reportId: this.$route.params.reportId
    };
  }
};
</script>
